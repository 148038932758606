import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import {
  IPhonebookContact,
  IPhonebookNumbersHttpResponse,
} from "../../interfaces/phonebook-number";

import { IPhonebook } from "../../interfaces/phonebook";

import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import ResellerRedirect from "../../components/ResellerRedirect";
import PhoneBookNumberForm from "./PhoneBookNumberForm";

import App from "../../App";
import { RouteComponentProps } from "react-router-dom";

const set = new MySettings();

interface IPhoneBookNumberPage extends RouteComponentProps<any>, IPageProps {}

const PhoneBookNumber: React.FC<IPhoneBookNumberPage> = (props) => {
  const [phoneBook, setPhoneBook] = useState<IPhonebook>({
    id: "",
    safeCustomersName: "",
    customersId: "",
    name: "",
    syncWith365: false,
  });
  const [contact, setContact] = useState<IPhonebookContact>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const getNumber = async () => {
      try {
        const data: IPhonebookNumbersHttpResponse = await set.getPhoneBook(
          props.match.params.phonebookID
        );
        // console.log("DATA", data);
        if (isMounted) {
          if (props.match.params.numberID === "new") {
            setPhoneBook(data.phonebook);
            setContact({ phoneBookId: props.match.params.phonebookID });
          } else {
            setPhoneBook(data.phonebook);
            setContact(
              data.records.filter(
                (contact) => contact.id === props.match.params.numberID
              )[0]
            );
          }
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getNumber();

    return () => {
      isMounted = false;
    };
  }, [props.match.params.numberID, props.match.params.phonebookID]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const data = await set.addPhoneBookEntry(contact);
      // console.log("data", data);
      const notification: INotification = {
        title: "Success",
        data: "New contact saved.",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "handleSubmit",
        srcData: window.location.pathname,
        srcComponent: "PhoneBookNumber",
      };
      App.newNotification(notification);
      let url = "/phonebook/" + data.phoneBookId;
      if (props.match.params.rid && props.match.params.rid.length > 0) {
        url = "/reseller/" + props.match.params.rid + url;
      }
      props.history.push(url);
    } catch (err) {
      console.log('PBB',err)
      let message='Problem adding the entry.'
      if(err && err.data){
          message=message+' '+err.data
      }
      App.newNotificationError(message)
    }
  };

  const handleUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const data = await set.updatePhoneBookEntry(contact);
      // console.log("data", data);
      const notification: INotification = {
        title: "Success",
        data: "Phone book Contact Updated",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "handleUpdate",
        srcData: window.location.pathname,
        srcComponent: "PhoneBookNumber",
      };
      App.newNotification(notification);
      let url = "/phonebook/" + data.phoneBookId;
      if (props.match.params.rid && props.match.params.rid.length > 0) {
        url = "/reseller/" + props.match.params.rid + url;
      }
      props.history.push(url);
    } catch (error) {
      console.log("error", error);
      const notification: INotification = {
        title: "Error",
        data: "Something Went Wrong",
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "handleSubmit",
        srcData: window.location.pathname,
        srcComponent: "PhoneBookNumber",
      };

      App.newNotification(notification);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handlechange call", event);
    setContact({ ...contact, [event.target.id]: event.target.value });
  };

  let url = "/phonebook/" + phoneBook.id;
  if (props.match.params.rid && props.match.params.rid.length > 0) {
    url = "/reseller/" + props.match.params.rid + url;
  }
  url='/voip/portal'+url

  return (
    <div className="content">
      <PageHead
        resellerID={props.resellerID}
        title={contact.id && contact.name ? contact.name : "New Contact"}
        parents={[
          { title: "Phone Books", url: "../../../PhoneBooks" },
          {
            title: phoneBook.name,
            url: url,
          },
        ]}
      ></PageHead>

      {loading === true ? (
        <Loading />
      ) : (
        <>
          {props.resellerID === undefined &&
          props.session.userType === "resellersContacts" ? (
            <ResellerRedirect />
          ) : (
            <PhoneBookNumberForm
              contact={contact}
              handleChange={handleChange}
              onSubmit={
                props.match.params.numberID === "new"
                  ? handleSubmit
                  : handleUpdate
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default PhoneBookNumber;
