
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import Loading from "../../components/loading";

import {
  ICallRecordingHttpRequest,

} from "../../interfaces/call-recording";


import { RouteComponentProps } from "react-router-dom";
import PageHead from "../../components/PageHead";
import ResellerRedirect from "../../components/ResellerRedirect";
import {
  IVoipService,
} from "../../interfaces/interfaces";
import CallRecordingSearchForm from "./CallRecordingSearchForm"
import { addDays, format, startOfDay } from "date-fns";
import { ICallRecordingSearchFormFields } from "../../interfaces/search-form";
import React, { useEffect, useRef, useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, } from "@material-ui/core";
import Error from "../../components/error";

import App from "../../App";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallRecordingTable from "./CallRecordingTable";

const formatDuration = (duration: any) => {
  var h, hms, m, mms, remaining, s, output;

  var ms = duration * 1;
  if (typeof ms !== "number") {
    ms = 0;
  }
  if (ms < 0) {
    ms = 0;
  }

  remaining = ms;
  h = Math.floor(remaining / 3600);
  hms = h * 3600;
  remaining = remaining - hms;
  m = Math.floor(remaining / 60);
  mms = m * 60;
  remaining = remaining - mms;
  s = Math.floor(remaining / 1);

  if (h <= 0) {
    h = "";
  } else if (h < 10) {
    h = "0" + h + ":";
  } else {
    h = h + ":";
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (s < 10) {
    s = "0" + s;
  }

  output = h + m + ":" + s;
  return output;
};

export enum CallRecPageState {
  ShowTable,
  Loading,
  NetworkError,
  NoResults,
}
const set = new MySettings();

interface ICallRecordingPage extends RouteComponentProps<any>, IPageProps { }
const dateMemo = new Date();
const startOfDateMemo = startOfDay(dateMemo);
const dateMemoOneDayAhead = addDays(dateMemo, 1);

const CallRecording = (props: ICallRecordingPage) => {
  const [pageState, setPageState] = useState(CallRecPageState.Loading);
  const [currentSearch, setCurrentSearch] = useState<ICallRecordingSearchFormFields>();


  const [services, setServices] = useState<IVoipService[]>();


  const [results, setResults] = useState<any[]>([]);


  const handleSearch = async (
    search: ICallRecordingSearchFormFields
  ) => {
    try {
      console.log("handle Search", search)
      setPageState(CallRecPageState.Loading);
      setCurrentSearch(search)
      //  let datetime = {}
      if (search.datetime) {

        console.log("handle Search", search)
        let gte = new Date(Date.parse(search.datetime.$gte));
        //  gte=startOfDay(gte)
        //   gte.setUTCHours(0,0,0,0);
        let lte = new Date(Date.parse(search.datetime.$lte));
        // lte = endOfDay(lte);
        // lte.setUTCHours(23,59,59,999);

        console.log("handle Search", gte, lte)        // const datetime = {
        //   $gte: format(Date.parse(search.datetime.$gte), "yyyy-MM-dd"),
        //   $lte: format(Date.parse(search.datetime.$lte), "yyyy-MM-dd") + "T24", //so we are inclusive
        // };

        const datetime = {
          $gte: format(gte, "yyyy-MM-dd"),
          $lte: format(lte, "yyyy-MM-dd") + "T24", //so we are inclusive
        };
        console.log("handle Search", datetime)
        const request: ICallRecordingHttpRequest = { ...search, datetime, extensionName: '' };

        if (search.source && search.source.length > 0) {
          request.source = search.source;
        }
        if (search.destination && search.destination.length > 0) {
          request.destination = search.destination;
        }
        if (search.ddi && search.ddi.length > 0) {
          request.ddi = search.ddi;
        }
        if (search.extensionName && search.extensionName.length > 0) {
          request.extensionName = search.extensionName;
        }
        const res = await set
          .getCallRecordings(request)
        let items: any = res.items;
        for (let i = 0; i < items.length; i++) {
          items[i].duration = formatDuration(items[i].duration);
        }
        setResults(items);

        setPageState(CallRecPageState.ShowTable)

      } else {
        const notification: INotification = {
          title: "Success",
          data: "Please set a start and end.",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      }
    } catch (exc:any) {
      if(exc && exc.data && exc.data.length>0){
        App.newNotificationError(exc.data)
      }
      setPageState(CallRecPageState.NetworkError)
    }
  };



  const isMounted = useRef(true);


  useEffect(() => {
    const getStuff = async () => {

      if (props.session.userType === "resellersContacts") {
        // console.log(this.props);
        if (props.resellerID !== undefined) {
          const response = await set.getVoipServicesCall(true,props.resellerID)
          const newSearch = { ...currentSearch };

          // const datetime = {}
          //     const datetime 
          newSearch.datetime = {
            $gte: startOfDateMemo.toISOString(),
            $lte: dateMemoOneDayAhead.toISOString(), //so we are inclusive
          };

          if (response.services.length >= 1) {
            newSearch.customersSitesServicesId = response.services[0].id;
          }
          setServices(response.services);
          setCurrentSearch(newSearch)
          await handleSearch(newSearch);
          setPageState(CallRecPageState.ShowTable);
        } else {

          setPageState(CallRecPageState.ShowTable);
        }
      } else {
        const response = await set.getVoipServicesCall(true)
        const newSearch = { ...currentSearch };

        newSearch.customersSitesServicesId = response.services[0].id;
        newSearch.datetime = {
          $gte: startOfDateMemo.toISOString(),
          $lte: dateMemoOneDayAhead.toISOString(), //so we are inclusive
        };
        setServices(response.services);


        setCurrentSearch(newSearch)
        await handleSearch(newSearch);
        setPageState(CallRecPageState.ShowTable);
      }

      //setData({ ...data, bands: tarifs.items })


    }



    if (isMounted.current && !results.length) {
      try {
        getStuff();
      } catch (error) {
        setPageState(CallRecPageState.NetworkError);
      }
    }

    return () => {
      isMounted.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resellerID, props.session.userType]);

  const reload = async () => {
    currentSearch && await handleSearch(currentSearch)
  }

  const RenderSwitch = () => {
    let customersSitesServicesId = ''
    if (currentSearch && currentSearch.customersSitesServicesId) {
      customersSitesServicesId = currentSearch.customersSitesServicesId
    }
    let callRecordingsAPILog:string[]=[]
    if(services!=null){
      for(let i=0;i<services.length;i++){
        let service=services[i]
        if(service.id===customersSitesServicesId){
          callRecordingsAPILog=service.callRecordingsAPILog
        }
      }
    }
    
    switch (pageState) {
      case CallRecPageState.Loading:
        return <Loading />;
      case CallRecPageState.ShowTable:
        return (
          <>
            {props.resellerID === undefined &&
              props.session.userType === "resellersContacts" ? (
              <ResellerRedirect />
            ) : (
              <>
                {(callRecordingsAPILog.length > 0) && (<>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography component={'span'} >Recordings Removed By API</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ margin: 0 }}>
                      {callRecordingsAPILog.map((line,index)=>{
                        return <div key={index}>{line}</div>
                      })}
                    </AccordionDetails>
                  </Accordion>
                  <br />
                </>)}
                <CallRecordingTable customersSitesServicesId={customersSitesServicesId} results={results} setPageState={setPageState} setResults={setResults} reload={reload} />
              </>
            )}
          </>
        );
      case CallRecPageState.NetworkError:
        return <Error />;
      default:
        return <></>;
    }
  };


  return <Grid container spacing={3} alignContent="flex-start">
    <Grid item xs={12}>
      <PageHead
        resellerID={props.resellerID}
        title="Call Recordings"
        parents={[]}
        subpages={[]}
      >

      </PageHead>
    </Grid>


    <Grid item xs={12}>
      {currentSearch && services && <CallRecordingSearchForm
        data={currentSearch}
        onSubmit={handleSearch}
        services={services}
        resellerId={props.resellerID}
      />}

      <RenderSwitch />
    </Grid>
  </Grid>
}

export default CallRecording;