import React, { useEffect, useState } from "react";
import "react-table/react-table.css";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import { Link, RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import App from "../../App";
import Error from "../../components/error";
import PageHead from "../../components/PageHead";
import VipBurgerMenu from "../../components/VipBurgerMenu";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { IPeopleGetHttpResponse, IPerson } from "../../interfaces/people";
import AssociateButton from "../../components/associateUserButton";
library.add(faWindowClose, faEdit);

export interface IPeopleState {
  // newPerson: IPerson;
  loading: boolean;
  people: IPerson[];
  error: boolean;
}

const set = new MySettings();
interface IPeoplePage extends RouteComponentProps<any>, IPageProps { }

const People: React.FC<IPeoplePage> = (props) => {
  console.log('PBB',props)
  const [people, setPeople] = useState<IPerson[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("STATE", resellerPeople);

  useEffect(() => {
    let isMounted = true;

    const getPeople = async () => {
      try {
        const peopleGetResponse: IPeopleGetHttpResponse = await set.peopleGet(
          '', props.resellerID
        );
        // console.log("DATA", peopleGetResponse);

        if (isMounted) {
          setPeople(peopleGetResponse.items);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);

        const notification: INotification = {
          title: "Error",
          data: "API Error Occurred. please retry if problem persists please contact support",
          variant: NOTIFICATION_TYPE.danger,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "People",
        };

        App.newNotification(notification);

        setError(true);

      }
    };

    getPeople();

    return () => {
      isMounted = false;
    };
  }, [props.resellerID]);

  const handlePersonDelete = async (id: string) => {
    if (
      window.confirm(
        "Are you sure you want to remove this person?"
      )
    ) {
      try {
        setLoading(true);

        await set.customerPersonRemove(id);

        setPeople(people.filter((person) => person.id !== id));

        const notification: INotification = {
          title: "Success",
          data: "Person removed.",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "PhoneBook",
        };

        App.newNotification(notification);
      } catch (error) {
        console.log(error);
        const notification: INotification = {
          title: "Problem Removing Person",
          data: error.data,
          variant: NOTIFICATION_TYPE.warning,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "PhoneBook",
        };

        App.newNotification(notification);
      }
      setLoading(false);
    }
  };

  const col = [
    {
      Header: "First Name",
      accessor: "firstName",
      sortable: true,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      sortable: true,
    },
    {
      Header: "Email Address",
      accessor: "emailAddress",
      sortable: true,
    },{
      Header: "Extension",
      accessor:"associatedExtension",
      sortable:true
    },
    {
      Header: "",
      accessor: "id", // String-based value accessors!
      Cell: (cellProps: any) => {
        const link: string = "./person/" + cellProps.value;

        return (
          <>
            <Link to={link} className="btn btn-outline-primary">
              Edit <FaIcon fixedWidth icon="edit" />
            </Link>
            <Button
              onClick={() => {
                handlePersonDelete(cellProps.value);
              }}
              variant="danger"
            >
              Remove <FaIcon icon="user-minus" color="white" />
            </Button>
            {props.resellerID && (<AssociateButton id={cellProps.value} buttonLabel="Impersonate" openInNewTab={false}></AssociateButton>)}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <PageHead resellerID={props.resellerID} title="People" parents={[]}>
          <VipBurgerMenu
            options={[
              {
                title: "New Person",
                link: "./person/new",
                isExternal: false,
              },
            ]}
          />
        </PageHead>

        {loading ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : (
          <>
            <VoipTable
              title="People"
              data={people}
              col={col}
              sortByStart="lastName"
              multiSelect={false}
            />
          </>
        )}
      </div>
    </>
  );
};

export default People;
