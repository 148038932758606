import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import Error from "../../components/error";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { IPageProps } from "../../interfaces/page-props";
import {
  IProvisionedDevice, IProvisionedDeviceResponse, IProvisioningGetHttpResponse
} from "../../interfaces/provisioning";
import MySettings from "../../static/settings";
import DeviceForm from "./DeviceForm";

const set = new MySettings();

interface IDevicePage extends RouteComponentProps<any>, IPageProps { }

const Device: React.FC<IDevicePage> = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [singleCustomerMode, setSingleCustomerMode] = useState(false);
  const [newUrlMode, setNewUrlMode] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [extensionName, setExtensionName] = useState("");
  const [data, setData] = useState<IProvisionedDevice>({
    id: "",
    uuid: "",
    extensionCallerid: "",
    extensionName: "",
    userAgent: "",
    provisioningId: "",
    customersId: "",
    customersSitesId: "",
    customersSitesId2: "",
    asteriskServerExtensionsId: "",
    asteriskServerExtensionsId2: "",
    deskPhoneId: "",
    provisioningSettings: {
      customFields: [],
      extraAccounts: []
    },
  });

  useEffect(() => {
    let isMounted = true;

    const getDevice = async (deviceId: string) => {
      try {
        // CUSTOMER MODE
        if (props.session.userType === "customersContacts") {
          // EXISTING DEVICE
          if (deviceId !== "new") {
            const provisioningGetResult: IProvisioningGetHttpResponse =
              await set.provisioningGet({ uuid: deviceId, name: "" });

            console.log("getData", provisioningGetResult);
            if (isMounted) {
              if (provisioningGetResult.items) {
                setData((prevState) => {
                  return {
                    ...prevState,
                    ...provisioningGetResult.items[0],
                  };
                });
              }

              setSingleCustomerMode(true);
              setLoading(false);
            }
          }
          // NEW DEVICE
          else {
            let uuid = "";
            let newUrlMode = false;
            //get inserted mac
            const parsed = queryString.parse(window.location.search);

            if (parsed.uuidForPairing) {
              uuid = parsed.uuidForPairing.toString();
              newUrlMode = true;
            }
            // console.log("window", parsed, window.location.search);

            if (isMounted) {
              setNewUrlMode(newUrlMode);
              setSingleCustomerMode(true);
              setData({
                extensionCallerid: "",
                uuid: uuid,
                extensionName: "",
                userAgent: "",
                id: "",
                provisioningId: "",
                customersId: "###",
                customersSitesId: "",
                customersSitesId2: "",
                asteriskServerExtensionsId: "",
                asteriskServerExtensionsId2: "",
                deskPhoneId: "",
                provisioningSettings: { customFields: [], extraAccounts: [] },
              });
              setLoading(false);
            }
          }
        } else {
          //RESELLER MODE
          const resellerCustomerSearchResult = await set.resellerCustomerSearch(
            "",false
          );

          let customers = resellerCustomerSearchResult.items.map(
            (item: any) => {
              let reshapedCustomers = { ...item.item, score: item.score };
              return reshapedCustomers;
            }
          );

          // EXISTING DEVICE
          if (deviceId !== "new") {
            const provisioningGetResult: IProvisioningGetHttpResponse =
              await set.provisioningGet({ uuid: deviceId, name: "" });

            if (isMounted) {
              if (provisioningGetResult.items) {
                setData((prevState) => {
                  return {
                    ...prevState,
                    ...provisioningGetResult.items[0],
                  };
                });
              }
              if (props.resellerID) {
                setSingleCustomerMode(true);
              }

              setCustomers(customers);
              setLoading(false);
            }
          }
          // NEW DEVICE
          else {
            let uuid = "";
            let newUrlMode = false;
            //get inserted mac
            const parsed = queryString.parse(window.location.search);

            // console.log("parsed", parsed);
            // console.log("window", window);

            if (parsed.uuidForPairing) {
              uuid = parsed.uuidForPairing.toString();
              newUrlMode = true;
            }

            if (isMounted) {
              setData({
                extensionCallerid: "",
                asteriskServerExtensionsId: "",
                asteriskServerExtensionsId2: "",
                customersSitesId: "",
                customersSitesId2: "",
                deskPhoneId: "",
                customersId: props.resellerID ? props.resellerID : "",
                uuid: uuid,
                extensionName: "",
                userAgent: "",
                id: "",
                provisioningId: "",
                provisioningSettings: {
                  customFields: [],
                  extraAccounts: []
                },
              });

              if (props.resellerID) {
                setSingleCustomerMode(true);
              }
              setCustomers(customers);
              setNewUrlMode(newUrlMode);

              setLoading(false);
            }
          }
        }
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    getDevice(props.match.params.id);

    return () => {
      isMounted = false;
    };
  }, [props.match.params.id, props.resellerID, props.session.userType]);

  // console.log("props", props);
  // console.log("state", data);

  const showCongratulations = newUrlMode && extensionName;

  const saveDevice = async (device: any) => {
    const url = "../provisioning";
    if(device && device.provisioningSettings && device.provisioningSettings.extraAccounts){
      for(let i=0;i<device.provisioningSettings.extraAccounts.length;i++){
        const extraAccount=device.provisioningSettings.extraAccounts[i]
        if(extraAccount.customersId==='###'){
          alert('You must choose a valid customer for every extra account.')
          return
        }
      }
    }
    //UPDATE
    if (device.id !== "") {
      try {
        setLoading(true);
        setData(device);
        const provisioningUpdateResult: IProvisionedDevice =
          await set.provisioningUpdate({
            id: device.id,
            uuid: device.uuid,
            userAgent: device.userAgent,
            provisioningId: device.provisioningId,
            extensionName: device.extensionName,
            extensionCallerid: device.extensionCallerid,
            provisioningSettings: device.provisioningSettings,
            customersId: device.customersId,
            ...device,
          });
        // console.log("provisioningUpdateResult", provisioningUpdateResult);
        setData(provisioningUpdateResult);
        setLoading(false);

        const alert: INotification = {
          title: "Success",
          data: "Device Saved!",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "Device",
          srcData: "success",
          srcFunction: "saveDevice",
        };
        App.newNotification(alert);

        props.history.push(url);
      } catch (error) {
        console.log(error);
        const alert: INotification = {
          title: "Error",
          data: "Something went wrong",
          variant: NOTIFICATION_TYPE.warning,
          srcComponent: "Device",
          srcData: "success",
          srcFunction: "saveDevice",
        };
        App.newNotification(alert);
      }
      setLoading(false);
    }
    //ADD NEW
    else {
      try {
        setLoading(true);
        setData(device);
        const resellerDeviceAddResult: IProvisionedDeviceResponse =
          await set.resellerDeviceAdd(
            device.uuid,
            device.customersId,
            device.customersSitesId,
            device.asteriskServerExtensionsId,
            device.customersSitesId2,
            device.asteriskServerExtensionsId2,
            device.deskPhoneId,
            device.provisioningSettings
          );

        setData(resellerDeviceAddResult.item);
        setExtensionName(resellerDeviceAddResult.extensionNumber);

        setLoading(false);
        const alert: INotification = {
          title: "Success",
          data: "Device created.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "Login",
          srcData: "success",
          srcFunction: "Device",
        };
        App.newNotification(alert);

        if (!newUrlMode) {
          props.history.push(url);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        const alert: INotification = {
          title: "Error",
          data: "Something went wrong",
          variant: NOTIFICATION_TYPE.warning,
          srcComponent: "Device",
          srcData: "warning",
          srcFunction: "saveDevice",
        };
        App.newNotification(alert);
      }
    }
  };

  return (
    <div className="content">
      <PageHead
        resellerID={props.resellerID}
        title={data.uuid ? data.uuid : "New Device"}
        parents={[{ title: "Provisioning - Devices", url: "../provisioning" }]}
      ></PageHead>

      {error ? (
        <Error />
      ) : loading ? (
        <Loading />
      ) : (
        <>
          {showCongratulations ? (
            <div className="text-center">
              <h2>Congratulations</h2>
              <p>Your phone is now paired with extension {extensionName}</p>
              <p>You can now close this window and begin using your phone.</p>

              <p>
                If this is not what you expected or you need any further support
                please contact
                {props.branding && props.branding.telephoneNumber}
              </p>
            </div>
          ) : (
            <DeviceForm
              data={data}
              userType={props.session.userType}
              newUrlMode={newUrlMode}
              customers={customers}
              onSubmit={saveDevice}
              singleCustomerMode={singleCustomerMode}
            />
          )}
        </>
      )}
    </div>
  );
};
export default Device;
