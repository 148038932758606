import { ThemeProvider } from "@material-ui/core/styles";
import pathToRegexp from "path-to-regexp";
import queryString from "query-string";
import React from "react";
import Container from "react-bootstrap/Container";
import "react-datepicker/dist/react-datepicker.css";
import Helmet from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./PrivateRoute";
import Footer from "./components/Footer";
import Notifier from "./components/MuiComponents/Notifier";
import Popup from "./components/MuiComponents/Popup";
import Navigation from "./components/Navigation";
import Notifications from "./components/Notifications";
import { MainContext } from "./context/main";
import mfaContext from "./context/mfa";
import { NOTIFICATION_TYPE } from "./enum/notification-type";
import { IBranding } from "./interfaces/branding";
import { IVoipSession } from "./interfaces/interfaces";
import { IMenuHttpResponse, IMenuOption } from "./interfaces/menu-option";
import { INotification } from "./interfaces/notification";
import NotFound from "./pages/NotFound";
import {
  RESELLER_ROUTE_CUSTOMER_ALLOW_DIAL_CODES,
  RESELLER_ROUTE_CUSTOMER_ALLOW_DIAL_CODES_NEW,
  RESELLER_ROUTE_PHONE_BOOK_LDAP_SETTINGS,
  RESELLER_ROUTE_PHONE_BOOK_UPLOAD,
  RESELLER_ROUTE_PROVISIONING_VIEW_CUSTOMER_URLS,
  RESELLER_ROUTE_PROVISIONING_VIEW_URLS,
  RESELLER_ROUTE_PSA_CONFIGURATION,
  RESELLER_ROUTE_PSA_CUSTOMER_MATCHING,
  RESELLER_ROUTE_PSA_PRODUCT_MATCHING,
  RESELLER_ROUTE_PSA_RECONCILIATION,
} from "./pages/ResellerHome";
import { PageDeclares } from "./pages/pagedeclare";
import MySettings from "./static/settings";
import { theme } from "./theme";

export interface IAppState {
  loaded: boolean;
  notifications: INotification[];
  menuOptions: IMenuOption[];
  loggedIn: boolean;
  // userName: string;
  branding?: IBranding;
  // userType: string;
  //redirectTo: string;
  sidebarOpen: boolean;
}

const set = new MySettings();
declare const window: any; //so we can access the settings globally for testing
window.mySettings = set;
// Route Protection

class App extends React.Component<any, IAppState> {
  _isMounted: boolean = false;

  static contextType = MainContext;

  static notifications: Notifications = new Notifications({});

  constructor(props: any, context: any) {
    super(props, context);
    //  this.setCustomerListener.bind(this);

    // console.log(props, context);
    this.state = {
      loaded: false,
      loggedIn: false,
      sidebarOpen: false,
      menuOptions: [],
      notifications: [],
      // redirectTo: "",
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  public componentDidMount = () => {
    this._isMounted = true;
    this._isMounted &&
      set
        .brandingGet(window.location.hostname)
        .then((brand: IBranding) => {
          console.log(brand)
          this._isMounted && this.getMenuOptionsHTTP(brand);
        })
        .catch((err) => {
          console.log(err);
          this._isMounted && this.getMenuOptionsHTTP().then((dd) => {});
        });
  };

  // this will check to see if were authenticated if not it will display a notification
  public componentDidUpdate() {
    const auth = this.isAuthenticated(window.location.pathname);
    //  console.log(history, location, auth, this.state);
    if (auth === false) {
      //const notification: INotification = { title: "Access Denied " + window.location.pathname, data: "Access to " + window.location.pathname + " is denied", variant: NOTIFICATION_TYPE.danger, srcFunction: "componentDidUpdate", srcData: window.location.pathname, srcComponent: 'App' };
      //console.log()

      const pathname = window.location.pathname.toString();
      if (pathname === "/" || pathname === "/voip/portal/") {
      } else {
        // Commented out for now because it just spams notifications we don't need
        // this.newNotification(notification, true);
      }
    }

    const session: IVoipSession = this.context.data.session;
    // console.log("ddddd", session);
    if (session.userType === "resellersContacts") {
      const values = queryString.parse(window.location.search);

      let copySesison = { ...this.context.data.session };

      if (values.resellerID !== undefined) {
        let dddd = "";
        if (values.resellerID !== null) {
          dddd = values.resellerID.toString();
        }

        if (
          copySesison.activeCustomerID === undefined ||
          copySesison.activeCustomerID !== dddd
        ) {
          copySesison.activeCustomerID = dddd;
          this._isMounted &&
            this.context.setData({
              branding: this.context.data.branding,
              session: copySesison,
            });
        }
      } else {
        // Not active reseller
        if (copySesison.activeCustomerID !== undefined) {
          copySesison.activeCustomerID = undefined;
          copySesison.activeCustomerName = undefined;
          this._isMounted &&
            this.context.setData({
              branding: this.context.data.branding,
              session: copySesison,
            });
        }
      }
    }
    return true;
  }
  // once the element is rendered it sets
  // this.notifications as that element this allows calling public function
  public setNoteRef = (element: Notifications) => {
    App.notifications = element;
  };

  // Auth check function
  public isAuthenticated = (path: string): boolean => {
    console.log(path, window.location);
    // override /login & /logout
    if (
      path === "/voip/portal/mfa" ||
      path === "/voip/portal/login" ||
      path === "/voip/portal/logout" ||
      path === "/voip/portal/privacy" ||
      path.indexOf("wallboard") > -1
    ) {
      return true;
    }

    if (path === "/voip/portal/" && mfaContext.loggedIn) {
      return true;
    }

    if (this.state !== undefined && this.state.menuOptions !== undefined) {
      let accessOk: boolean = false;
      this.state.menuOptions.map((e: any) => {
        const regexpNumbers = pathToRegexp("/voip/portal" + e.path);
        const result = regexpNumbers.exec(path);
        if (result !== null) {
          accessOk = true;
        }
        return result;
      });
      if (accessOk) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  public render = () => {
    const { loaded } = this.state;
    if (loaded === false) {
      return <span>Loading...</span>;
    } else {
      const { branding, session } = this.context.data;

      const { menuOptions } = this.state;
     
      console.log(branding)
      const pageDeclare = new PageDeclares(
        this,
        session,
        menuOptions,
        branding,
        this.menuReloadListener,
        this.brandingReloadListener
      );

      const defaultProtectedRouteProps: any = {
        isAuthenticated: this.isAuthenticated,
        newNote: this.notificationListener,
        branding: branding,
      };

      let brandingTitle = "";

      if (branding !== undefined && branding.name) {
        brandingTitle = branding.name;
      }
      //   {redirectTo !== '' && <Redirect to={redirectTo} />}

      return (
        <>
          <Helmet>
            <title>{brandingTitle}</title>
          </Helmet>
          <ThemeProvider theme={theme}>
            <Navigation />
            <Notifier />
            <Popup />
            <Notifications ref={(c: Notifications) => this.setNoteRef(c)} />
            <Container fluid>
              <Switch>
                <Route path="/pbxaudittrail" component={pageDeclare.pbxAuditTrailPage} />
                <Route
                  path="/phonebooksnew"
                  component={pageDeclare.phonebooksFormPage}
                />
                <Route
                  path="/phonebooksettings/:id"
                  component={pageDeclare.phonebookLDAPSettingsPage}
                />
                <Route
                  path={RESELLER_ROUTE_PROVISIONING_VIEW_URLS}
                  component={pageDeclare.provisioningViewURLsPage}
                />
                <Route
                  path={RESELLER_ROUTE_PSA_CONFIGURATION}
                  component={pageDeclare.psaConfigurationPage}
                />
                <Route
                  path={RESELLER_ROUTE_PSA_CUSTOMER_MATCHING}
                  component={pageDeclare.psaCustomerMatchingPage}
                />
                <Route
                  path={RESELLER_ROUTE_PSA_PRODUCT_MATCHING}
                  component={pageDeclare.psaProductMatchingPage}
                />
                <Route
                  path={RESELLER_ROUTE_PSA_RECONCILIATION}
                  component={pageDeclare.psaReconciliationPage}
                />

                {/*<Route path="/actionstep" component={pageDeclare.actionStepPage} />*/}
                <Route
                  path="/wallboards"
                  component={pageDeclare.wallboardsListPage}
                />
                <Route
                  path="/wallboard/:id"
                  component={pageDeclare.wallboardPage}
                />
                <Route path="/login" component={pageDeclare.loginPage} />
                <Route
                  {...defaultProtectedRouteProps}
                  path="/loginpasswordreset"
                  component={pageDeclare.loginPasswordReset}
                />
                <Route
                  path="/passwordreset/:id"
                  component={pageDeclare.passwordReset}
                />

                <Route
                  path="/chromeplugin/auth"
                  component={pageDeclare.chromePluginPage}
                />

                <Route
                  {...defaultProtectedRouteProps}
                  path="/newcustomer"
                  component={pageDeclare.demoPage}
                />

                <Route path="/logout" component={pageDeclare.logoutPage} />
                <Route path="/privacy" component={pageDeclare.privacyPage} />
                <Route path="/mfa" component={pageDeclare.mfaPage} />
                <Route path="/help" component={pageDeclare.helpPage} />
                <Route
                  path="/actionstep"
                  component={pageDeclare.actionStepPage}
                />
                <Route
                  path="/teamscallrecording"
                  component={pageDeclare.teamsCallRecordingPage}
                />
                <Route
                  path="/office365"
                  component={pageDeclare.office365Page}
                />
                <Route
                  path="/connectwisecontactsintegration"
                  component={pageDeclare.connectwiseContactsPage}
                />
                <Route
                  path="/reseller/:rid/voipservice/:id/provisioning"
                  component={pageDeclare.voipServiceProvisioningPage}
                />
                <ProtectedRoute
                  path={"/meetingrooms/:id"}
                  {...defaultProtectedRouteProps}
                  component={pageDeclare.voipServiceMeetingRoom}
                />
                <ProtectedRoute
                  path={"/meetingrooms"}
                  {...defaultProtectedRouteProps}
                  component={pageDeclare.voipServiceMeetingRooms}
                />
                <ProtectedRoute
                  path={"/meetingroomsadd"}
                  {...defaultProtectedRouteProps}
                  component={pageDeclare.voipServiceMeetingRoomsForm}
                />

                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  exact
                  path="/"
                  component={pageDeclare.homePage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/tariffs"
                  component={pageDeclare.tariffsPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/standardTariff"
                  component={pageDeclare.standardTariffPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/tariff/:id"
                  component={pageDeclare.tariffPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/allocateNumbers"
                  component={pageDeclare.resellerAllocateNumbers}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/number/:id/"
                  component={pageDeclare.numberPage}
                />
                <Route
                  {...defaultProtectedRouteProps}
                  path="/phonebookNumber/:phonebookID/:numberID"
                  component={pageDeclare.phonebookNumberPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/numbers"
                  component={pageDeclare.numbersPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/resellerProvisioning"
                  component={pageDeclare.resellerProvisioningSettingsPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/numberTrackers"
                  component={pageDeclare.numberTrackersPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/phonebook/:id"
                  component={pageDeclare.phonebookPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/phonebooks"
                  component={pageDeclare.phonebooksPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/profile"
                  component={pageDeclare.profilePage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/resellerProfile"
                  component={pageDeclare.resellerProfilePage}
                />
                <Route path="/customerProfile" component={() => {
                  window.location.href = '/voip/react/api/v1/reseller/contact/customer/profile';
                  return null;
                }}></Route>
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/areacoderouting"
                  component={pageDeclare.areaCodeRoutingPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/callrecordings"
                  component={pageDeclare.callRecordingPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/calllogs"
                  component={pageDeclare.callLogPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/people"
                  component={pageDeclare.peoplePage}
                />

                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/virtualreceptionist/homepage"
                  component={pageDeclare.receptionistPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/virtualreceptionisthome"
                  component={pageDeclare.receptionistHomePage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/vrcustomer/:id"
                  component={pageDeclare.customerDetailPage}
                />

                <Route
                  {...defaultProtectedRouteProps}
                  path="/vrmessageadd/:numbersId/:customersContactsId"
                  component={pageDeclare.addMessagePage}
                />

                <Route
                  {...defaultProtectedRouteProps}
                  path="/virtualreceptionistsummary/:numberId"
                  component={pageDeclare.chatPage}
                />

                <Route
                  {...defaultProtectedRouteProps}
                  path="/virtualreceptionistmessage/:id"
                  component={pageDeclare.chatMessagePage}
                />

                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/provisioning"
                  component={pageDeclare.devicesPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/incomingCallStats"
                  component={pageDeclare.incomingCallStatsPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/person/:id"
                  component={pageDeclare.personPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/device/:id"
                  component={pageDeclare.devicePage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/emailreports"
                  component={pageDeclare.emailReportsPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/emailreport/:id"
                  component={pageDeclare.emailReportPage}
                />
                <Route
                  {...defaultProtectedRouteProps}
                  path="/voiceroutings/:customersSitesServicesId/:id"
                  component={pageDeclare.voiceRoutingPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/resellers"
                  component={pageDeclare.resellerPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/customer/:id/:form"
                  component={pageDeclare.customerPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/incoming-call/:id"
                  component={pageDeclare.incomingCallRedirect}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/permission/:id"
                  component={pageDeclare.permssionPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/permissions"
                  component={pageDeclare.permssionsPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/customerdefaultgroup/:id"
                  component={pageDeclare.customerDefaultGroupPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/customerdefaultgroups"
                  component={pageDeclare.customerDefaultGroupsPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/sites"
                  component={pageDeclare.sitesPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/site/:id"
                  component={pageDeclare.sitePage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/phonebookupload/:phoneBookId"
                  component={pageDeclare.phoneBookUpload}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/newService/:id"
                  component={pageDeclare.newServicePage}
                />
                <Route
                  {...defaultProtectedRouteProps}
                  path="/service/:id/:pageMode"
                  component={pageDeclare.servicePage}
                />

                <Route
                  {...defaultProtectedRouteProps}
                  path="/creditnotes"
                  component={pageDeclare.resellerCreditNotesPage}
                />
                <Route
                  {...defaultProtectedRouteProps}
                  path="/creditnote/:id"
                  component={pageDeclare.resellerCreditNotePage}
                />

                <Route
                  {...defaultProtectedRouteProps}
                  path="/invoices"
                  component={pageDeclare.resellerInvoicesPage}
                />
                <Route
                  {...defaultProtectedRouteProps}
                  path="/invoice/:id"
                  component={pageDeclare.resellerInvoicePage}
                />
                <Route
                  {...defaultProtectedRouteProps}
                  path="/resellerpeople"
                  component={pageDeclare.resellerPeoplePage}
                />
                <Route
                  {...defaultProtectedRouteProps}
                  path="/resellerperson/:id"
                  component={pageDeclare.resellerPersonPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/reseller/:id"
                  component={pageDeclare.resellerHome}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/numberTracker/:id"
                  component={pageDeclare.numberTrackerPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/reseller"
                  component={pageDeclare.resellerPage}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  path="/service/:id"
                  component={pageDeclare.servicePage}
                />

                <Route component={NotFound} />
              </Switch>
            </Container>
            <Footer />
          </ThemeProvider>
        </>
      );
    }
  };

  // this listens for new Notifications and passes to the notifications components new function
  public notificationListener = (note: INotification): Promise<boolean> => {
    // console.log("notificationListener", note);
    return App.newNotification(note);
  };

  // Listens for menu reload requests
  public menuReloadListener = (): Promise<boolean> => {
    return new Promise<any>((resolve) => {
      this.getMenuOptionsHTTP().then((dd) => {
        console.log(dd);
        /// this.setState(dd);
        resolve(true);
      });
    });
  };

  // Listens for branding reload requests
  public brandingReloadListener = (): Promise<boolean> => {
    this.setState({ loaded: false });
    return new Promise<any>((resolve, reject) => {
      this._isMounted &&
        set
          .brandingGet(window.location.hostname)
          .then((brand: IBranding) => {
            this._isMounted && this.setState({ loaded: true, branding: brand });
            resolve(true);
          })
          .catch((exc) => {
            reject();
          });
    });
  };

  // get menu options from server (HTTP)
  public getMenuOptionsHTTP = (brand?: IBranding): Promise<any> => {
    return new Promise<any>((resolve) => {
      const url = set.getMenu();
      this._isMounted &&
        set
          .doAPIRequest<IMenuHttpResponse>(url, {
            queryString: window.location.search,
          })
          .then((data: IMenuHttpResponse) => {
            if (data && data.redirectTo && data.redirectTo.length > 0) {
              window.location.href = data.redirectTo;
            }
            const stateUpdate: any = {};

            console.log("IMenuHttpResponse", data);
            /*const dataCopy = data.menuOptions.filter(
              (option) => option.title !== "Wallboards" && option.path !== ""
            );

            data = { ...data, menuOptions: dataCopy };*/

            if (data.name) {
              stateUpdate.userName = data.name;
            }
            stateUpdate.userType = data.userType;
            stateUpdate.loggedIn = true;
            stateUpdate.loaded = true;
            stateUpdate.emailAddress = data.emailAddress;
            stateUpdate.canSeeCustomerProfile=data.canSeeCustomerProfile;

            let session: IVoipSession = {
              userName: stateUpdate.userName,
              loggedIn: true,
              emailAddress: data.emailAddress,
              userType: stateUpdate.userType,
              usesMFA: data.usesMFA,
              oldPortalLink: data.oldPortalURL,
              topMenu: data.topMenu,
              canSeeCustomerProfile:data.canSeeCustomerProfile
            };
            //   stateUpdate.session = session;

            if (session.userType === "resellersContacts") {
              let menuOpt: IMenuOption[] = [];

              //reseller base stuff
              menuOpt.push({
                title: "Style guide",
                path: "/demo",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });

              menuOpt.push({
                title: "Home",
                path: "/",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Customers",
                path: "/resellers",
                visibleTop: true,
                visibleAdmin: true,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Customer Default Groups",
                path: "/customerdefaultgroups",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              /*
              menuOpt.push({
                title: "Invoices",
                path: "/invoices",
                visibleTop: false,
                visibleAdmin: true,
                visibleDash: false,
              });*/
              menuOpt.push({
                title: "Phonebook Upload",
                path: "/phonebookupload/:phoneBookId",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Profile",
                path: "/profile",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Reseller Profile",
                path: "/resellerProfile",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Customer",
                cls: true,
                path: "/customer/:id/:form",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Customer Home",
                path: "/reseller/:rid",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Allocate Phone Numbers",
                path: "/allocateNumbers",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "People",
                path: "/resellerpeople",
                visibleTop: false,
                visibleAdmin: true,
                visibleDash: false,
              });
              /*
              menuOpt.push({
                title: "VIP Receptionist",
                path: "/reseller/:rid/virtualreceptionist/homepage",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "VIP Receptionist Chat",
                path: "/reseller/:rid/virtualReceptionistSummary/:numberId",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "VIP Receptionist Chat",
                path: "/reseller/:rid/virtualReceptionistMessage/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "VIP Receptionist Message",
                path: "/virtualreceptionistmessage/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "VIP Receptionist",
                path: "/virtualreceptionistsummary/:numberId",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              */
              menuOpt.push({
                title: "Permissions",
                path: "/permissions",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Provisioning",
                path: "/provisioning",
                visibleTop: false,
                visibleAdmin: true,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Device",
                path: "/device/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Reseller Provisioning",
                path: "/resellerProvisioning",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Numbers",
                path: "/numbers",
                visibleTop: true,
                visibleAdmin: true,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Number",
                path: "/number/:id/:mode",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Tariffs",
                path: "/tariffs",
                visibleTop: false,
                visibleAdmin: true,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Tariff",
                path: "/tariff/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Standard Tariff",
                path: "/standardTariff",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Number Trackers",
                path: "/numberTrackers",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Number Trackers",
                path: "/numberTracker/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Permission",
                path: "/permission/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Customer Default Group",
                path: "/customerdefaultgroup/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              // reseller Customer stuff
              menuOpt.push({
                title: "Numbers",
                path: "/reseller/:rid/numbers",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Call Recordings",
                path: "/reseller/:rid/callrecordings",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Email Reports",
                path: "/reseller/:rid/emailreports",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "People",
                path: "/reseller/:rid/people",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Phone Books",
                path: "/reseller/:rid/phonebooks",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Phone Books New",
                path: "/reseller/:rid/phonebooksnew",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Phone Books New Number",
                path: "/reseller/:rid/phonebook/phonebookNumber/:phonebookID/:numberID",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Provisioning",
                path: "/reseller/:rid/provisioning",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Call Logs",
                path: "/reseller/:rid/calllogs",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Number",
                path: "/reseller/:rid/number/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Voice Routing",
                path: "/reseller/:rid/:customersSitesServicesId/voicerouting/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Voice Routings",
                path: "/reseller/:rid/:customersSitesServicesId/voiceroutings",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Email Reports",
                path: "/reseller/:rid/:customersSitesServicesId/emailreports",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Email Report",
                path: "/reseller/:rid/:customersSitesServicesId/emailreport/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Number",
                path: "/reseller/:rid/number/:id/:mode",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Number Trackers",
                path: "/reseller/:rid/numberTrackers",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Number Tracker",
                path: "/reseller/:rid/numberTracker/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Incoming Call Stats",
                path: "/reseller/:rid/incomingCallStats",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Phonebook",
                path: "/reseller/:rid/phonebook/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Phonebook LDAP Settings",
                path: RESELLER_ROUTE_PHONE_BOOK_LDAP_SETTINGS,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Phonebook Upload",
                path: RESELLER_ROUTE_PHONE_BOOK_UPLOAD,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Allocate Numbers",
                path: "/reseller/:rid/allocateNumbers",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "View Customer Provisioning Settings",
                path: RESELLER_ROUTE_PROVISIONING_VIEW_CUSTOMER_URLS,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Reseller Customer Allow Dial Codes",
                path: RESELLER_ROUTE_CUSTOMER_ALLOW_DIAL_CODES,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Reseller Customer Allow Dial Codes New",
                path: RESELLER_ROUTE_CUSTOMER_ALLOW_DIAL_CODES_NEW,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "View Provisioning URLs",
                path: RESELLER_ROUTE_PROVISIONING_VIEW_URLS,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Configure PSA",
                path: RESELLER_ROUTE_PSA_CONFIGURATION,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Customer Matching PSA",
                path: RESELLER_ROUTE_PSA_CUSTOMER_MATCHING,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Product Matching PSA",
                path: RESELLER_ROUTE_PSA_PRODUCT_MATCHING,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Reconciliation PSA",
                path: RESELLER_ROUTE_PSA_RECONCILIATION,
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Person",
                path: "/reseller/:rid/person/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Device",
                path: "/reseller/:rid/device/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Email Report",
                path: "/reseller/:rid/emailreport/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Permissions",
                path: "/reseller/:rid/permissions",
                visibleTop: false,
                visibleAdmin: true,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Permission",
                path: "/reseller/:rid/permission/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });

              menuOpt.push({
                title: "Sites",
                path: "/reseller/:rid/sites",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Site",
                path: "/reseller/:rid/site/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Service",
                path: "/reseller/:rid/service/:id/:pageMode",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "New Service",
                path: "/reseller/:rid/newService/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Number",
                path: "/reseller/:rid/phonebookNumber/:phonebookID/:numberID",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              /*
              PBB 2024-04-11 this seems to be a dead feature
              menuOpt.push({
                title: "Meeting Rooms",
                path: "/reseller/:rid/meetingrooms",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Meeting Rooms",
                path: "/reseller/:rid/meetingrooms/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Meeting Rooms",
                path: "/reseller/:rid/meetingroomsadd",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });*/
              menuOpt.push({
                title: "Wallboards",
                path: "/reseller/:rid/wallboards",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: true,
              });
              menuOpt.push({
                title: "Internet Service Enquiry",
                path: "/reseller/:rid/internetserviceenquiry/:id/summary",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "Teams Call Recording service",
                path: "/reseller/:customersid/teamscallrecordingservice/:id/summary",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              /*
              menuOpt.push({
                title: "VIP Receptionist",
                path: "/virtualreceptionisthome",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "VIP Receptionist Customer",
                path: "/vrcustomer/:id",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              menuOpt.push({
                title: "VIP Receptionist New Message",
                path: " /vrmessageadd/:numbersId/:customersContactsId",
                visibleTop: false,
                visibleAdmin: false,
                visibleDash: false,
              });
              */
              stateUpdate.menuOptions = menuOpt;
            } else {
              if (data.menuOptions) {
                let i;
                for (i = 0; i < data.menuOptions.length; i++) {
                  if (data.menuOptions[i].title === "Numbers") {
                    data.menuOptions[i].path = "/numbers";
                  }

                  if (data.menuOptions[i].path === "") {
                    //is an external link
                    data.menuOptions[i].isExternal = true;
                    switch (data.menuOptions[i].title) {
                      case "Reports":
                        data.menuOptions[i].path = set.reportsGetLink();
                        break;
                      // case "Wallboards":
                      //   data.menuOptions[i].path = ;
                      //   break;
                    }
                  }
                }

                data.menuOptions.push({
                  title: "Profile",
                  path: "/profile",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                /*data.menuOptions.push({
                  title: "Wallboards",
                  path: "/wallboards",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });*/
                data.menuOptions.push({
                  title: "Home",
                  path: "/",
                  visibleTop: true,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Call Logs",
                  path: "/calllogs",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Phonebook Upload",
                  path: "/phonebookupload/:phoneBookId",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Incoming Call Redirect",
                  path: "/incoming-call/:id",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Number",
                  path: "/number/:id/:mode",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title:'Allocate Numbers',
                  path:'/allocateNumbers',
                  visibleAdmin:false,
                  visibleDash:false,
                  visibleTop:false
                })
                data.menuOptions.push({
                  title: "Number Trackers",
                  path: "/numberTrackers",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Incoming Call Stats",
                  path: "/incomingCallStats",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Phonebook",
                  path: "/phonebook/:id",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "VIP Receptionist",
                  path: "/virtualreceptionist/homepage",
                  visibleTop: false,
                  visibleAdmin: true,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Number",
                  path: "/phonebookNumber/:phonebookID/:numberID",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Person",
                  path: "/person/:id",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Number Tracker",
                  path: "/numberTracker/:id",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Device",
                  path: "/device/:id",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Email Report",
                  path: "/emailreport/:id",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "VIP Receptionist",
                  path: "/virtualreceptionisthome",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Meeting Room",
                  path: "/meetingrooms/:id",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "VIP Receptionist Customer",
                  path: "/vrcustomer/:id",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "VIP Receptionist New Message",
                  path: " /vrmessageadd/:numbersId/:customersContactsId",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.push({
                  title: "Meeting Room",
                  path: "/meetingroomsadd",
                  visibleTop: false,
                  visibleAdmin: false,
                  visibleDash: false,
                });
                data.menuOptions.reverse();
                stateUpdate.menuOptions = data.menuOptions;
              }
            }
            this._isMounted &&
              this.context.setData({
                branding: brand,
                session: session,
              });
            this._isMounted && this.setState(stateUpdate);

            resolve(stateUpdate);
          })
          .catch((error) => {
            console.log("fetchError", error);
            const clearState = {
              loggedIn: false,
              menuOptions: [],
              loaded: true,
            };

            this._isMounted &&
              this.context.setData({
                branding:(brand ? brand: this.context.data.branding),
                session: {
                  loggedIn: false,
                  userName: "",
                  emailAddress: "",
                  userType: "",
                },
              });
            this._isMounted && this.setState(clearState);
            resolve(clearState);
          });
    });
  };

  static newNotificationError = (message: string) => {
    const notification: INotification = {
      title: "Problem",
      data: message,
      variant: NOTIFICATION_TYPE.danger,
      srcComponent: "",
      srcData: "",
      srcFunction: "",
    };
    App.newNotification(notification);
  };

  static newNotificationSuccess = (message: string) => {
    const notification: INotification = {
      title: "Success",
      data: message,
      variant: NOTIFICATION_TYPE.success,
      srcComponent: "",
      srcData: "",
      srcFunction: "",
    };
    App.newNotification(notification);
  };

  // make notification (add to notifications)
  static newNotification = (
    note: INotification,
    setIgnoreDidUpdate?: boolean
  ): Promise<boolean> => {
    if (App.notifications) {
      return App.notifications.createNotification(note);
    }
    return new Promise<boolean>((resolve, reject) => {
      resolve(false);
    });
  };

  public resellerCheck = (): boolean => {
    const { session } = this.context.data;
    if (session.userType === "resellersContacts") {
      // check see if
      if (session.activeCustomerID === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
}

export default App;
