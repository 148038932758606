
import MySettings, { } from "../../static/settings";
import "react-table/react-table.css";

import { IPageProps } from "../../interfaces/page-props";

import Loading from "../../components/loading";
import { RouteComponentProps } from "react-router-dom";

import PageHead from "../../components/PageHead";

import {
  IEmailReport,
} from "../../interfaces/email-reports";

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


import App from "../../App";
import { useEffect, useRef, useState } from "react";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";
import MuiDropdownButton, { IDropdownButtonProps } from "../../components/MuiComponents/MuiDropdownButton";
import { ButtonGroup, Grid, IconButton } from "@material-ui/core";
import Error from "../../components/error";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";



enum PageState {
  ShowTable,
  Loading,
  NetworkError,
  NoResults,
}
export interface IEmailReportsState {

  reports: IEmailReport[];
}

interface IEmailReportsProps extends RouteComponentProps<any>, IPageProps { }

const set = new MySettings();


const EmailReports = ({ match, session, resellerID }: IEmailReportsProps) => {
  const [pageState, setPageState] = useState(PageState.Loading);
  const [data, setData] = useState<IEmailReportsState>({
    reports: [],
    // search: {},
  });



  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,

      },
    },



    {
      name: "id",
      label: " ",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ align: "right" }),
        customBodyRenderLite: (dataIndex: number) => {
          const element = data.reports[dataIndex];
          let link: string = "./emailreport/" + element.id;
          if (
            session.userType === "resellersContacts" &&
            resellerID !== undefined
          ) {
            link += "?resellerID=" + resellerID;
          }
          //return (
          // <div>
          //   <Link to={link} className="btn btn-info">
          //     <FaIcon fixedWidth className="whiteIcon" icon="edit" />
          //   </Link>


          return <ButtonGroup>
            <IconButton color="primary" href={link}>
              <EditIcon />
            </IconButton>

            <IconButton color="secondary" type="button" onClick={(ev) => {
              ev.preventDefault();
              deleteReport(element.id)
            }}>
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        }
      },
    },
  ]

  const isMounted = useRef(false);



  useEffect(() => {
    const get = async () => {
      try {
        const zRoutes = await set.getVoipServicesCall(false,resellerID)
        if (zRoutes.services.length === 0) {
          setPageState(PageState.ShowTable);
          App.newNotificationError('This customer has no voip service therefore can not have any email reports.')
        } else {
          let serviceId = zRoutes.services[0].id;
          if (
            match.params.customersSitesServicesId &&
            match.params.customersSitesServicesId.length > 0
          ) {
            serviceId = match.params.customersSitesServicesId;
          }
          const data = await set
            .emailReportsGet({ customersSitesServicesId: serviceId })

          //     console.log(data);
          const stateUpdate: any = {};

          if (data.items) {
            stateUpdate.reports = data.items;
          }
          setData(stateUpdate);
          setPageState(PageState.ShowTable);


        }

      } catch (error: any) {
        App.newNotificationError(error.data)
        setPageState(PageState.NetworkError)
      }

    }

    if (isMounted.current === false) {
      isMounted.current = true;
      get();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pageState, resellerID]);



  const deleteReport = async (reportID: string) => {
    if (window.confirm('Are you sure you want to delete this email report?')) {

      setPageState(PageState.Loading)

      try {

        await set
          .emailReportsRemove(reportID)

        // console.log("jkjkjk", res);
        const alert: INotification = {
          title: "Success",
          data: "Report deleted.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "Login",
          srcData: "success",
          srcFunction: "postLogin",
        };

        let reports = [...data.reports];
        const index = reports.findIndex(
          (iii: IEmailReport) => iii.id === reportID
        );
        reports.splice(index, 1);
        App.newNotification(alert);
        setPageState(PageState.ShowTable)
        setData({ reports: reports });



        const zRoutes = await set.getVoipServicesCall(false,resellerID)
        if (zRoutes.services.length === 0) {
          setPageState(PageState.ShowTable);
          App.newNotificationError('This customer has no voip service therefore can not have any email reports.')
        } else {
          let serviceId = zRoutes.services[0].id;
          if (
            match.params.customersSitesServicesId &&
            match.params.customersSitesServicesId.length > 0
          ) {
            serviceId = match.params.customersSitesServicesId;
          }
          const data = await set
            .emailReportsGet({ customersSitesServicesId: serviceId })

          //     console.log(data);
          const stateUpdate: any = {};

          if (data.items) {
            stateUpdate.reports = data.items;
          }
          setData(stateUpdate);
          setPageState(PageState.ShowTable);


        }

      } catch (error: any) {
        App.newNotificationError(error.data)
        setPageState(PageState.NetworkError)
      }
    }

  };


  const RenderSwitch = () => {
    switch (pageState) {
      case PageState.Loading:
        return <Loading />;

      case PageState.ShowTable:
        return (
          <MuiTableWrapper
            title=""
            data={data.reports}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: true,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "customersName",
                direction: "asc",
              },
            }}
          />
        );


      case PageState.NetworkError:
        return <Error />;
      default:
        return <></>;
    }
  };
  const dropdownOptions: IDropdownButtonProps["options"] = [];

  let linkN: string = "./emailreport/new";
  dropdownOptions.push({ label: "Add Email Report", link: linkN, target: '' });
  return <Grid container spacing={3} alignContent="flex-start">
    <Grid item xs={12}>
      <PageHead

        resellerID={resellerID}
        title="Email Reports"
        parents={[]}
        subpages={[]}
      >
        <MuiDropdownButton label="More Options" options={dropdownOptions} />
      </PageHead>
    </Grid>


    <Grid item xs={12}>
      <RenderSwitch />
    </Grid>
  </Grid>
}
export default EmailReports;
// export default class EmailReports extends React.Component<
//   IEmailReportsProps,
//   IEmailReportsState
// > {
//   constructor(props: any) {
//     super(props);

//     this.state = {
//       customerServicesId: "",
//       error: false,
//       loading: true,
//       reports: [],
//     };
//     // this.clickNew = this.clickNew.bind(this);
//   }

//   public render() {
//     const { loading, reports } = this.state;

//     {
//       const col = [
//         {
//           Header: "Name",
//           accessor: "name",
//           sortable: true,
//         },
//         {
//           Header: "",
//           accessor: "id", // String-based value accessors!
//           Cell: (props: any) => {
//             //const link: string = './emailreport/' + props.value;

//             let link: string = "./emailreport/" + props.value;
//             if (
//               this.props.session.userType === "resellersContacts" &&
//               this.props.resellerID !== undefined
//             ) {
//               link += "?resellerID=" + this.props.resellerID;
//             }
//             return (
//               <ButtonGroup>
//                 <Link to={link} className="btn btn-outline-primary">
//                   Edit <FaIcon fixedWidth icon="edit" />
//                 </Link>
//                 <Button
//                   variant="outline-danger"
//                   onClick={(ev: any) => {
//                     if (
//                       window.confirm(
//                         "Are you sure you want to delete this report?"
//                       )
//                     ) {
//                       ev.preventDefault();
//                       this.deleteReport(props.value);
//                     }
//                   }}
//                 >
//                   Delete <FaIcon fixedWidth className="redIcon" icon="trash" />
//                 </Button>
//               </ButtonGroup>
//             );
//           },
//         },
//       ];
//       let linkN: string = "./emailreport/new";

//       return (
//         <>
//           <div className="content">
//             <PageHead
//               resellerID={this.props.resellerID}
//               title="Email Reports"
//               parents={[]}
//             >
//               <VipBurgerMenu
//                 options={[
//                   { title: "New Report", link: linkN, isExternal: false },
//                 ]}
//               />
//             </PageHead>

//             {this.props.resellerID === undefined &&
//             this.props.session.userType === "resellersContacts" ? (
//               <ResellerRedirect />
//             ) : loading ? (
//               <Loading />
//             ) : (
//               <VoipTable
//                 title="Reports"
//                 data={reports}
//                 col={col}
//                 multiSelect={false}
//                 sortByStart="name"
//               />
//             )}
//           </div>
//         </>
//       );
//     }
//   }

//   public resolveData = (data: any) => data.map((row: any) => row);

//   public componentDidMount = () => {
//     // trigger db call here
//     // this.get();

//     if (this.props.session.userType === "resellersContacts") {
//       if (this.props.resellerID !== undefined) {
//         this.get();
//       } else {
//         this.setState({ loading: false });
//       }
//     } else {
//       this.get();
//     }
//   };

//   public deleteReport = (reportID: string) => {
//     this.setState({ loading: true });
//     set
//       .emailReportsRemove(reportID)
//       .then((res: ISuccessAPIResponse) => {
//         // console.log("jkjkjk", res);
//         const alert: INotification = {
//           title: "Success",
//           data: "Report Deleted!",
//           variant: NOTIFICATION_TYPE.success,
//           srcComponent: "Login",
//           srcData: "success",
//           srcFunction: "postLogin",
//         };

//         let reports = [...this.state.reports];
//         const index = reports.findIndex(
//           (iii: IEmailReport) => iii.id === reportID
//         );
//         reports.splice(index, 1);
//         this.makeNotification(alert);

//         this.setState({ loading: false, reports: reports });
//       })
//       .catch((exception) => {
//         console.log(exception);
//         catchME(this, exception, false);
//       });
//   };
//   public makeNotification = (note: INotification) => {
//     return App.newNotification(note);
//   };
//   // get phonebooks
//   public get = (): Promise<boolean> => {
//     return new Promise<boolean>((resolve, reject) => {
//       set
//         .getVoipServicesCall(this.props.resellerID)
//         .then((zRoutes: IVoipServicesRequest) => {
//           if(zRoutes.services.length===0){
//             const stateUpdate:any={
//               loading:false,
//               reports:[]
//             }
//             this.setState(stateUpdate)
//             App.newNotificationError('This customer has no voip service therefore can not have any email reports.')
//           }else{
//             let serviceId = zRoutes.services[0].id;
//             if (
//               this.props.match.params.customersSitesServicesId &&
//               this.props.match.params.customersSitesServicesId.length > 0
//             ) {
//               serviceId = this.props.match.params.customersSitesServicesId;
//             }
//             set
//               .emailReportsGet({ customersSitesServicesId: serviceId })
//               .then((data: IEmailReportsHttpResponse) => {
//                 //     console.log(data);
//                 const stateUpdate: any = {};
//                 stateUpdate.loading = false;
//                 if (data.items) {
//                   stateUpdate.reports = data.items;
//                 }
//                 this.setState(stateUpdate);
//                 resolve(true);
//               })
//               .catch((exception) => {
//                 console.log(exception);
//                 catchME(this, exception, true);
//                 reject(exception);
//               });
//           }
//         })
//         .catch((exception) => {
//           console.log(exception);
//           catchME(this, exception, true);
//         });
//     });
//   };
// }
