import axios from "axios";
import queryString from "query-string";
import React, { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import mfaContext from "../context/mfa";
import MySettings from "../static/settings";

import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import BrandedLogo from "../components/BrandedLogo";
import { openSnackbar } from "../components/MuiComponents/Notifier";
import Loading from "../components/loading";

import Helmet from "react-helmet";
import { IPageProps } from "../interfaces/page-props";

const set = new MySettings();

interface ILoginProps extends RouteComponentProps<any>, IPageProps {
  reloadMenu(): Promise<boolean>;
}
const Login = (props: ILoginProps) => {
  const [loading, setLoading] = useState(false);

  const redirectToHome = () => {
    const parsed = queryString.parse(window.location.search);

    let loop = 1;
    if (parsed.loop) {
      loop = parseInt(parsed.loop.toString());
    }
    let redirect = parsed.redirect;

    delete parsed.redirect;

    const stringifiedp = queryString.stringify(parsed);
    if (loop > 2) {
      redirect = "";
    }

    if (redirect === "/voip/portal") {
      redirect = "";
    }

    if (redirect === undefined) {
      redirect = "";
    }
    setTimeout(() => {
      if (props.history) {
        if (redirect !== "" && loop < 3) {
          props.history.push("/" + redirect + "?" + stringifiedp);
        } else {
          props.history.push("/");
        }
      }
    }, 500);
  };

  const redirectToMFA = () => {
    if (props.history) {
      props.history.push("/mfa" + window.location.search);
    }
  };

  const postLogin = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    const loginRequest = {
      username: username,
      password: password,
      queryString: window.location.search,
    };
    let showUsernameError = false;

    const url = set.getLoginUrl();
    setLoading(true);
    try {
      const response = await axios.post(url, loginRequest, {
        withCredentials: true,
      });
      showUsernameError = !response.data.success;
      localStorage.setItem("type", response.data.type);
      if (response.data.redirectTo) {
        window.location.href = response.data.redirectTo;
        return false;
      } else if (response.data.success) {
        mfaContext.loggedIn = true; // so that app won't put us in a redirect loop
        await props.reloadMenu();
        redirectToHome();
        return showUsernameError;
      } else if (response.data.mfaAttemptToken) {
        mfaContext.label = loginRequest.username;
        mfaContext.secret = response.data.mfaSecret;
        mfaContext.mfaAttemptToken = response.data.mfaAttemptToken;
        redirectToMFA();
        return showUsernameError;
      }
    } catch (err) {
      let error: any = err;
      let message = "There was a problem logging in. Please try again.";
      if (error && error.message) {
        message = message + " " + error.message;
      }

      openSnackbar({
        message: message,
        type: "error",
      });

      showUsernameError = false;
    }
    setLoading(false);
    return showUsernameError;
  };

  if (props.session.loggedIn) {
    redirectToHome();
  }

  return <>    
    <Helmet>
    <title>{props && props.branding && props.branding.name} Login</title>
    </Helmet>

    <Form
      onSubmit={async ({ username, password }, { getState }) => {
        const showUsernameError = await postLogin(username, password);

        const formState = getState();

        if (showUsernameError && formState.touched) {
          console.log("PBB 124", showUsernameError);
          return {
            username: "Incorrect username or password.",
          };
        }
      }}
      validate={(values) => {
        const errors: any = {};

        const requiredFields = ["username", "password"];

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = "Required.";
          }
        });

        return errors;
      }}
      render={({ handleSubmit, submitting, dirtySinceLastSubmit, values }) => (
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "368px", margin: "auto" }}
          noValidate
        >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} style={{ marginBottom: "32px" }}>
              <Box display="flex" justifyContent="center">
                <BrandedLogo />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                Sign in to your portal
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <Typography variant="h6" align="center">
                Enter login details below
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Email Address</Typography>
              <Field name="username">
                {({ input, meta }) => {
                  let isError = false;
                  if (
                    (meta.error && meta.touched) ||
                    (meta.submitError != null && meta.submitError.length > 0)
                  ) {
                    isError = true;
                  }
                  return (
                    <TextField
                      {...input}
                      name="username"
                      fullWidth
                      required
                      type="email"
                      variant="outlined"
                      size="small"
                      error={isError}
                      helperText={isError ? meta.submitError : ""}
                    />
                  );
                }}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Password</Typography>
              <Field name="password">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="password"
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Field>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ paddingTop: "0px", marginBottom: "8px" }}
            >
              <Link
                to={{
                  pathname: "/loginpasswordreset",
                  state: { username: values.username },
                }}
              >
                Forgot My Password
              </Link>
            </Grid>

            <Grid item xs={12}>
              {loading ? (
                <Loading size="80px" />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  type="submit"
                  size="large"
                  style={{ height: "56px" }}
                >
                  Log In
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    />
  </>;
};

export default Login;
