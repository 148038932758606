import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { ButtonGroup, IconButton } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DeleteIcon from '@material-ui/icons/Delete';
import NotesIcon from '@material-ui/icons/Notes';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import React from 'react';

interface iCallRecordingButtons {
    recording: any;
    playerHref: string;
    downloadHref: string;
    activePlayer: string;
    setActivePlayer: (id: string) => void;
    setProtection: (id: string, v: boolean) => void;
    deleteRecord: (id: string) => void;
    setTranscription: (d: any) => void;
    setTranscriptionModal: (d: boolean) => void;
    setRecordingModal: (d: boolean) => void;
    setRecordings: (d: any[]) => void;
}
const CallRecordingButtons = ({ activePlayer, setActivePlayer, recording, downloadHref, playerHref, setProtection, deleteRecord, setTranscription, setTranscriptionModal, setRecordingModal, setRecordings }: iCallRecordingButtons) => {
    //const [showPlayer, setShowPlayer] = useState(false)

    console.log(recording)

    return <>
        {activePlayer === recording.id && <>
            <AudioPlayer
                autoPlay
                src={playerHref}
                onPlay={e => console.log("onPlay")}
            // other props here
            />
        </>}
        {/* {JSON.stringify(recording)} */}
        <ButtonGroup>

            {/* Lock */}
            {activePlayer === recording.id ? <IconButton title="Stop Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setActivePlayer("");
            }}>
                <StopIcon />
            </IconButton> : <IconButton title="Play Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setActivePlayer(recording.id);
            }}>
                <PlayArrowIcon />
            </IconButton>}
            {!recording.isProtected ? <IconButton title="Lock Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setProtection(recording.id, true);
            }}>
                <LockIcon />
            </IconButton> : <IconButton title="Un-Lock Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setProtection(recording.id, false);
            }}>
                <LockOpenIcon />
            </IconButton>}
            <IconButton title="Delete" color="primary" style={{ color: "red" }} onClick={(ev) => {
                ev.preventDefault();
                if (
                    window.confirm(
                        "Are you sure you want to delete this recording?"
                    )
                ) {
                    deleteRecord(recording.id);
                }
            }}>
                <DeleteIcon />
            </IconButton>

            {recording.transcription && recording.transcription.length > 0 && <IconButton title="View Transcription" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setTranscription({
                    message: recording.transcription,
                    sentence: recording.transcriptionSentences,
                });
                setTranscriptionModal(true)
            }}>
                <NotesIcon />
            </IconButton>}


            <IconButton title="Email Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setRecordings([recording.id])
                setRecordingModal(true)
            }}>
                <EmailIcon />
            </IconButton>






            <IconButton title="Download Recording" color="primary" target="_new" href={downloadHref}>
                <GetAppIcon />
            </IconButton>



        </ButtonGroup>


    </>
}

export default CallRecordingButtons