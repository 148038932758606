import React, { useEffect, useState } from "react";
import Loading from "../../components/loading";
import MySettings from "../../static/settings";
import MuiSelect from "../../components/MuiComponents/MuiSelect";

const set = new MySettings();

type option = {
  value: {
    id: string;
  };
  label: string;
};

const GetExtensionOptionsForCallRecordingSearch = ({
  name,
  label,
  resellerId,
  customersSitesServicesId,
  ...rest
}: any) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<option[]>([]);

 console.log("input", name, rest);

  useEffect(() => {
    const getExtensions = async () => {
      try {
        const result = await set.extensionsGetForCustomer(
          resellerId ? resellerId : "",
          ["US", "HD", "EX"],
          customersSitesServicesId ? customersSitesServicesId : ''
        );

        //remove types that do not apply to us
        if (result.service && result.service.extensionTypesToShowOnCallRecordingSearch === 'Hot Desks') {
          result.items = result.items.filter(extension => extension.type === 'HD')
        }

       console.log("data GetExtensionOptions", result);
        const reshapedResult = result.items.map(
          (item: { id: string; callerid: string; smdrName: string }) => ({
            value:item.smdrName,
            
            label: item.callerid,
          })
        );

        reshapedResult.unshift({
          value:  "",
        
          label: "",
        });

        reshapedResult.sort(compare);
        setData(reshapedResult);
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    };

    getExtensions();
  }, [resellerId, customersSitesServicesId]);

  const compare = (a: option, b: option) => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
  };

  if (loading) return <Loading size="38px" />;
  console.log(data)
  return (
    <>{data.length > 0 ? <MuiSelect
      name={name}
      label={label}
      options={data}
    /> : <p>
      You are not currently associated with any extensions. Please contact
      your VoIP provider.
    </p>}
      {/* {data.length > 0 ? (
        <Select
          options={data}
          {...input}
          {...rest}
          searchable
          value={input.value}
          onChange={input.onChange}
          styles={{
            control: (styles) => ({
              ...styles,
              fontSize: "1rem",
              height: "38px",
            }),
            option: (styles) => ({
              ...styles,
              minHeight: 50,
              fontSize: "1rem",
            }),
            input: (provided) => ({
              ...provided,
              paddingBottom: "0",
            }),
            valueContainer: (styles) => ({
              ...styles,
              height: "38px",
              paddingTop: "0",
              paddingBottom: "0",
            }),
          }}
        />
      ) : (
        <p>
          You are not currently associated with any extensions. Please contact
          your VoIP provider.
        </p>
      )} */}
    </>
  );
};

export default GetExtensionOptionsForCallRecordingSearch;
